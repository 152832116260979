import axios, {AxiosResponse} from 'axios'
import {FilesPayload} from '../models/payload/FilesPayload'
import client from '../client'
import filesServiceClient from '../filesServiceClient'

export function post(payload: FilesPayload): Promise<AxiosResponse<any>> {
  return filesServiceClient.post<any>(`/Files`, payload)
}

export function get(key?: string | null, pageNumber?: number | null) {
  let path = '/Files'
  if (key != null) {
    path += `/${key}`
  }
  if (pageNumber != null) {
    path += `?pageNumber=${pageNumber}&`
  }
  return filesServiceClient.get<any>(path)
}

export function deleteFile(key: string) {
  return filesServiceClient.delete(`/Files/${key}`)
}
