import axios from 'axios'
import coreClient from '../coreClient'

export function getProfile(userId: string) {
  const path = `/Participations/${userId}/Profile`
  return coreClient.get(path)
  // return coreClient.get(path, {
  //   headers: {accept: 'application/json'},
  // })
}
