import axios from 'axios'
import client from '../client'
import coreClient from '../coreClient'
import {ChampionshipPayload} from '../models/response/ChampionshipPayload'

export function getChampionshipWinnings(userId: string) {
  return coreClient.get<ChampionshipPayload[]>(`/Championships/Winnings?UserId=${userId}`, {
    headers: {accept: 'application/json'},
  })
}

export function getWinners(pageNumber?: number, pageSize?: number, start?: any, end?: any) {
  let path = `/Championships/Winners`
  if (pageNumber !== null || pageSize !== null || start !== null || end !== null) {
    path += `?`
  }
  if (pageNumber) {
    path += `PageNumber=${pageNumber}&`
  }
  if (pageSize) {
    path += `PageSize=${pageSize}&`
  }
  if (start) {
    path += `StartDateStart=${start}&`
  }
  if (end) {
    path += `StartDateEnd=${end}`
  }
  return coreClient.get(path)
}

export function getChampionship(
  startDate: string,
  endDate: string,
  pageNumber?: number | null,
  searchData?: string
) {
  var path = '/Wallets/RankingPoints/Leaderboard'
  if (startDate !== null || endDate !== null || pageNumber !== null || searchData !== null) {
    path += '?'
  }
  if (startDate != null) {
    path += `StartDate=${startDate}&`
  }
  if (endDate != null) {
    path += `EndDate=${endDate}&`
  }
  if (pageNumber != null) {
    path += `Page=${pageNumber}&`
  }
  if (searchData != null) {
    path += `UserId=${searchData}&`
  }
  return coreClient.get(path)
}
