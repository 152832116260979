import coreClient from "../coreClient";
import { DeviceMetaDataPayload } from "../models/response/DeviceMetaDataPayload";

export function get(userId:number|string, participationId?: number|string){
    let path = `/DeviceMetadatas`;
    if (userId !== null || participationId!==null){
        path+=`?`
    }
    if (userId){
        path+=`userId=${userId}&`
    }
    if (participationId){
        path+=`ParicipationId=${participationId}`
    }
    return coreClient.get<DeviceMetaDataPayload[]>(path)
}