import {UsersPayload} from '../models/response/UsersPayload'
import identityClient from '../identityClient'
import {SingleUser} from '../models/response/UserPayload'

export function getById(userId: string) {
  return identityClient.get<SingleUser>(`/Users/${userId}`, {
    headers: {accept: 'application/json'},
  })
}

export function editUser(userId: string, payload: any) {
  return identityClient.put<SingleUser>(`/Users/${userId}`, payload)
}

export function getUsers(pageNumber: number, pageSize: number, start?: any, end?: any) {
  const path = start
    ? `/Users?Page=${pageNumber}&PerPage=${pageSize}&CreateDateStart=${start}&CreateDateEnd=${end}`
    : `/Users?Page=${pageNumber}&PerPage=${pageSize}`
  return identityClient.get<UsersPayload[]>(path, {
    headers: {accept: 'application/json'},
  })
}

export function deleteUserById(userId: string) {
  return identityClient.delete(`/Users/${userId}`, {
    headers: {accept: 'application/json'},
  })
}

export function blockUserById(userId: string) {
  return identityClient.put(`/Users/${userId}/Block`, {
    headers: {accept: 'application/json'},
  })
}
