import { formatNumberWithCommas } from '../../../utilitites/formatNumber'
import { DtPicker } from 'react-calendar-datetime-picker'

export type SelectedDates = { startDate: string; endDate: string }

type Props = {
  value: string | number
  text: string
  handledate?: (dates: SelectedDates) => void
  color?: string
}

export default function TopBoxes({ value, text, handledate, color = '#FFFFFF' }: Props) {
  const handleDateChange = (selectedDate) => {
    // Check if selectedDate is an object with year, month, and day properties
    if (
      selectedDate &&
      selectedDate.year !== undefined &&
      selectedDate.month !== undefined &&
      selectedDate.day !== undefined
    ) {
      // Construct a Date object from the properties
      const date = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day) // month is zero-based

      const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`

      // Format the date as dd/mm/yyyy
      const startDate = formattedDate + ' 00:00:00'
      const endDate = formattedDate + ' 23:59:59'
      if (handledate) {
        handledate({ startDate, endDate })
      }
      // Use the formatted date
    } else {
      console.error('Invalid date format')
    }
  }

  const formattedNumber = formatNumberWithCommas(value)

  return (
    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
      <div className='card card-flush h-xl-100' style={{ backgroundColor: color }}>
        <div className='card-header py-7'>

          <div className='m-0' style={{width: '100%'}}>
            <div className='d-flex align-items-center mb-2 justify-content-between'>
              <span className='fs-lg-1 fw-bold text-800 text-black me-2 lh-1 ls-n2'>
                {formattedNumber}
              </span>
              <div style={{ position: 'relative', zIndex: 10, width: '110px' }}>
                <DtPicker onChange={handleDateChange} placeholder='YYYY-MM-DD' />{' '}
              </div>
            </div>
            <span className='fs-6 fw-semibold text-500 text-black'>{text}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
