import styles from './championshipList.module.scss'
import {useEffect, useState} from 'react'
import api from '../../../../api'
import ChampionshipRow from '../championshipRow/championshipRow'
import InfiniteScroll from 'react-infinite-scroll-component'
import {formatNumberWithCommas} from '../../../../utilitites/formatNumber'

const MINUTE_MS = 60000

const ChampionshipList = ({date, searchData}) => {
  const [users, setUsers] = useState<any>([])
  const [page, setPage] = useState(1)
  const [searchPage, setSearchPage] = useState(1)

  const [datePart, setDatePart] = useState('')
  useEffect(() => {
    getServerTime()
  }, [])

  useEffect(() => {
    getServerTime()
  }, [])

  useEffect(() => {
    if (datePart) {
      setUsers([])
      const resetPage = 1
      if (page !== resetPage) {
        setPage(resetPage)
      }
      fetchData(resetPage, searchData)
    }
    // const interval = setInterval(() => {
    //   setUsers([]);
    //   fetchData();
    // }, MINUTE_MS);
    // return () => clearInterval(interval);
  }, [date, datePart, searchData])

  const isDataPresent = (data: any): boolean => !!data?.trim?.()

  const getServerTime = async () => {
    try {
      const resp = await api.systemParameters.getServerTime()
      const dateTimeString = resp.data.toString()
      setDatePart(dateTimeString?.split(' ')[0])
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async (pageNum?: number, userID?: string) => {
    let startDate = datePart + ' 00:10:00'
    let endDate = datePart + ' 23:59:59'
    const pageToPass = userID ? null : pageNum ? pageNum : page
    const userIDtoPass = userID
    if (isDataPresent(date?.startDate)) {
      startDate = date?.startDate
      endDate = date?.endDate
    }
    try {
      const response = await api.championship.getChampionship(
        startDate,
        endDate,
        pageToPass,
        userIDtoPass
      )
      setUsers((prevItems: any) => [...prevItems, ...response.data?.items])
      setSearchPage(response.data.page)
      if (response.data?.page) {
        setPage((prevPage) => prevPage + 1)
      } else {
        setPage(0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <InfiniteScroll
      dataLength={users.length}
      next={fetchData}
      hasMore={searchData ? false : true} // Replace with a condition based on your data source
      loader={''}
      endMessage={''}
    >
      <section className={styles.userListSection}>
        {users &&
          (users.length > 1
            ? users.map((user: any, i: number) => {
                return (
                  <ChampionshipRow
                    key={i}
                    position={searchData ? 50 * (searchPage - 1) + i + 1 : i + 1}
                    nickname={user?.nickname}
                    userId={user?.userId}
                    points={formatNumberWithCommas(user.points)}
                    amount={formatNumberWithCommas(user?.amount)}
                    country={user.country?.code2}
                    tournamentName={user?.tournament?.name}
                    img={user?.avatar2D}
                    isSpecial={user?.userId === searchData}
                  ></ChampionshipRow>
                )
              })
            : users.length === 1 && (
                <ChampionshipRow
                  position={users[0]?.position}
                  nickname={users[0]?.nickname}
                  userId={users[0]?.userId}
                  points={formatNumberWithCommas(users[0]?.points)}
                  amount={formatNumberWithCommas(users[0]?.amount)}
                  country={users[0]?.country?.code2}
                  tournamentName={users[0]?.tournament.name}
                  img={users[0]?.avatar2D}
                ></ChampionshipRow>
              ))}
      </section>
    </InfiniteScroll>
  )
}
export default ChampionshipList
