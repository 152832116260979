import axios, { AxiosResponse } from "axios";
import { RedeemInfo, WalletsPayload } from "../models/response/WalletsPayload";
import { CreateWalletsPayload } from "../models/payload/CreateWalletsPayload";
import client from "../client";
import coreClient from "../coreClient";

let axiosConfig = {
    headers: {
      accept: 'application/json',
    },
  }


export function getWallets() {
    return client.get<WalletsPayload[]>(`/Wallets`, {
      headers: {accept: 'application/json'},
    })
  }

  export function getWalletsById( id: number) {
    return client.get<WalletsPayload[]>(`/Wallets/${id}`, {
      headers: {accept: 'application/json'},
    })
  }

  export function getWalletsReedemInfo( UserId: string) {
    // return coreClient.get<RedeemInfo>(`/Wallets/${UserId}/Coins/RedeemInfo`, {
    //   headers: {accept: 'application/json'},
    // })
    return coreClient.get(`/Wallets/${UserId}/Coins/RedeemInfo`)

  }

  export function postWallets( id: number, payload: CreateWalletsPayload  ): Promise<AxiosResponse<any>> {
    return client.post<any>(`/Wallets`, payload, axiosConfig)
  }

  export function postWalletsReedem( id: number, payload: any): Promise<AxiosResponse<any>> {
    return client.post<any>(`/Wallets/Coins/Redeem`, payload, axiosConfig)
  }

  export function postWalletsCallBack( id: number, payload: any): Promise<AxiosResponse<any>> {
    return client.post<any>(`/Wallets/Coins/Redeem/Callback`, payload, axiosConfig)
  }
  
  export function updateWallets(id: number, payload: any): Promise<AxiosResponse<any>> {
    return client.put<any>(
      `/Wallets/${id}`,
      payload,
      axiosConfig
    )
  }
  

  export function DeleteWallets(id:any,  ): Promise<AxiosResponse<any>> {
    return client.delete<any>(
      `/Wallets/${id}`
    )
  }

  