export const getFormattedDate = () => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`
  const startDate = formattedDate + ' 00:00:00'
  const endDate = formattedDate + ' 23:59:59'
  return {startDate, endDate}
}
